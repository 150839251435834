<template>
  <el-dialog
      :key="rowData.id"
     v-if="dialogShow"
     :append-to-body="true"
     :visible="dialogShow"
     :before-close="handleClose"
     title="返佣设置"
     width="800px"
  >
    <div class="outer-commission-set" style="border-bottom: inset;">
      <span class="set-title">代理等级设置:</span>
      <div class="set-right" style="width:200px;margin-right:10px;">
        <el-select v-model="selfCustLvl" placeholder="代理等级">
          <el-option
            v-for="item in ibLevelOptions"
            :key="item.levelId"
            :label="item.levelName"
            :value="item.levelId">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="doSubmit(true)">保存</el-button>
    </div>
    <div v-if="isShowForeignSetup" class="outer-commission-set" style="border-bottom: inset;">
      <span class="set-title">子代理一致外佣:</span>
      <div class="set-right" style="width:200px;margin-right:10px;">
        <el-radio-group v-model="foreignConfig"  :disabled='!isAllowEditCommi'>
          <el-radio  label="1">是</el-radio>
          <el-radio  label="0">否</el-radio>
        </el-radio-group>
      </div>
      <el-button v-if="isAllowEditCommi" @click="unionSaveHandle" type="primary">保存</el-button>
    </div>
    <div v-if="isShowForeignSetup" class="outer-commission-set" style="border-bottom: inset;">
      <span class="set-title" >外佣统计开关:</span>
      <div class="set-right" style="width:200px;margin-right:10px;">
         <el-radio-group v-model="foreignStatistics"  :disabled='!isAllowEditCommi'>
          <el-radio  label="1">打开</el-radio>
          <el-radio  label="0">关闭</el-radio>
        </el-radio-group>
      </div>
      <el-button
          v-if="isAllowEditCommi"
          @click="computerSaveHandle"
          type="primary"
      >保存</el-button>
    </div>
    <div class="outer-commission-set">
<!--      子代理: 全部是必选,加红*符号,-->
<!--      1子代理只显示主代理支持的大组,2.子代理只能修改不能清空-->
      <span
          class="set-title no-star"
          :class="{'is-sub-agent': isMainAgent == false}"
      >外佣设置:</span>
      <!--外佣设置非必选可清空 (但至少填一项) todo-->
      <div class="set-right">

        <div
            v-if="stdGroupFlag"
            class="group-line">
          <span class="margin-right10">{{stdInfo.groupName}}</span>
          <el-select
            v-model="stdGroup"
            placeholder="选择分组"
            class="margin-right10"
            :clearable="clearableFlag"
            :disabled='!isAllowEditCommi'
          >
            <el-option
              v-for="(item,index) in stdInfo.groupDetail"
              :key="index"
              :label="item.desc"
              :value="item.groupDetailId"
            >
            </el-option>
          </el-select>
<!--          <el-button v-if="isAllowEditCommi" @click="stdSaveHandle" type="primary">保存</el-button>-->
        </div>
        <div
            v-if="proGroupFlag"
            class="group-line">
          <span class="margin-right10">{{proInfo.groupName}}</span>
          <el-select
            class="margin-right10"
            v-model="proGroup"
            placeholder="选择分组"
            :clearable="clearableFlag"
            :disabled='!isAllowEditCommi'
          >
            <el-option
              v-for="(item,index) in proInfo.groupDetail"
              :key="index"
              :label="item.desc"
              :value="item.groupDetailId"
            >
            </el-option>
          </el-select>
<!--          <el-button v-if="isAllowEditCommi" @click="proSaveHandle" type="primary">保存</el-button>-->
        </div>
        <div
            v-if="ecnGroupFlag"
            class="group-line">
          <span class="margin-right10">{{ecnInfo.groupName}}</span>
          <el-select
            class="margin-right10"
            v-model="ecnGroup"
            placeholder="选择分组"
            :clearable="clearableFlag"
            :disabled='!isAllowEditCommi'
          >
            <el-option
              v-for="(item,index) in ecnInfo.groupDetail"
              :key="index"
              :label="item.desc"
              :value="item.groupDetailId"
            >
            </el-option>
          </el-select>
<!--          <el-button v-if="isAllowEditCommi" @click="ecnSaveHandle" type="primary">保存</el-button>-->
        </div>
        <div
            v-if="centGroupFlag"
            class="group-line">

          <span class="margin-right10">{{centInfo.groupName}}</span>
          <el-select
            class="margin-right10"
            v-model="centGroup"
            placeholder="选择分组"
            :clearable="clearableFlag"
            :disabled='!isAllowEditCommi'
          >
            <el-option
              v-for="(item,index) in centInfo.groupDetail"
              :key="index"
              :label="item.desc"
              :value="item.groupDetailId"
            >
            </el-option>
          </el-select>
<!--          <el-button v-if="isAllowEditCommi" @click="ecnSaveHandle" type="primary">保存</el-button>-->
        </div>
        <div
            v-if="stpGroupFlag"
            class="group-line">

          <span class="margin-right10">{{stpInfo.groupName}}</span>
          <el-select
              class="margin-right10"
              v-model="stpGroup"
              placeholder="选择分组"
              :clearable="clearableFlag"
              :disabled='!isAllowEditCommi'
          >
            <el-option
                v-for="(item,index) in stpInfo.groupDetail"
                :key="index"
                :label="item.desc"
                :value="item.groupDetailId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="outer-commission-set" >
      <span class="set-title">注册开户默认组:</span>
      <div class="set-right default-select-group">
        <el-radio-group v-model="defaultGroup">
          <el-radio
              v-if="stdInfo.groupId && stdGroup"
              :label="1"
          >STD</el-radio>
          <el-radio
              v-if="proInfo.groupId && proGroup"
              :label="2"
          >PRO</el-radio>
          <el-radio
              v-if="ecnInfo.groupId && ecnGroup"
              :label="3"
          >ECN</el-radio>
          <el-radio
              v-if="centInfo.groupId && centGroup"
              :label="4"
          >Cent</el-radio>
          <el-radio
              v-if="stpInfo.groupId && stpGroup"
              :label="5"
          >STP</el-radio>
        </el-radio-group>
      </div>
      <el-button
          type="primary"
          @click="commissionOuterSubmit"
      >保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { setCustLvlOuter,queryGroupDetailAll,saveGroupDetail,queryDefaultGroupDetail , updateCustInfoConfig} from '@/api/ib/ibMerchantInfo.js'
import { querySubLevleAndRules } from '@/api/ib/tManager.js';
export default {
  name: 'comsteupform',
  props: {
    rowData: {
      type: Object,
    },
    dialogShow: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    let _this = this;
    return {
      loading: false,
      outerType:1,
      outerComm: 0,
      merId:'',
      outerCommTypeOptions:[
        {key:1,display_name:'加点'},
        {key:2,display_name:'加手续费'}
      ],
      ibLevelOptions:[],
      selfCustLvl: _this.rowData.levelId,
      //是否允许编辑外佣配置
      isAllowEditCommi: _this.rowData.ibRank==2 && _this.rowData.foreignConfig==1?false:true,
      //是否显示一致外佣和外佣统计配置：主代理和一致外佣的子代理显示

      isShowForeignSetup: _this.rowData.ibRank==1 ||(_this.rowData.ibRank==2 && _this.rowData.foreignConfig==1)?true:false,
      foreignConfig: _this.rowData.foreignConfig+'',
      foreignStatistics: _this.rowData.foreignStatistics+'',

      //
      stdGroup:'',
      proGroup:'',
      ecnGroup:'',
      centGroup:'',
      stpGroup: '',
      stdInfo:{
        groupId: 0,
        groupDetail:[],
      },
      proInfo:{
        groupId: 0,
        groupDetail:[],
      },
      ecnInfo:{
        groupId: 0,
        groupDetail:[],
      },
      centInfo: {
        groupId: 0,
        groupDetail:[],
      },
      stpInfo: {
        groupId: 0,
        groupDetail:[],
      },

      defaultGroup: '',
      closeNeedRefresh: false,

    }
  },

  watch: {
    outerCommType(news,olds){
      this.outerComm = 0
    },
    rowData:{
      handler(newVal){
        if(newVal.ibRank ==1){
          this.getSubLevelAndRule(0);
        }else {
          this.getSubLevelAndRule(newVal.belongId);
        }
        if(newVal.levelId){
          this.selfCustLvl = newVal.levelId
        }
        if(newVal){
          this.isAllowEditCommi = newVal.ibRank==2&&newVal.foreignConfig==1?false:true;
          this.isShowForeignSetup = newVal.ibRank==1 ||(newVal.ibRank==2&&newVal.foreignConfig==1)?true:false;
          this.foreignConfig = newVal.foreignConfig+'';
          this.foreignStatistics = newVal.foreignStatistics+'';
        }
      },
      deep: true
    },
    dialogShow(newVal){
      if(newVal){
        this.getGroups();
        if(this.rowData.ibRank ==1){
          this.getSubLevelAndRule(0);
        }else {
          this.getSubLevelAndRule(this.rowData.belongId);
        }
      }else {
        this.closeSetOriginal();
      }
    }
  },
  computed: {
    isMainAgent() {
      return this.rowData.ibRank == 1;
    },
    clearableFlag(){
      if(this.isMainAgent){
        return true;
      }else {
        return false;
      }
    },
    stdGroupFlag(){
      if(this.isMainAgent){
        return this.stdInfo.groupId;
      }else {
        return this.stdGroup;
      }
    },
    proGroupFlag(){
      if(this.isMainAgent){
        return this.proInfo.groupId;
      }else {
        return this.proGroup;
      }
    },
    ecnGroupFlag(){
      if(this.isMainAgent){
        return this.ecnInfo.groupId;
      }else {
        return this.ecnGroup;
      }
    },
    centGroupFlag(){
      if(this.isMainAgent){
        return this.centInfo.groupId;
      }else {
        return this.centGroup;
      }
    },
    stpGroupFlag(){
      if(this.isMainAgent){
        return this.stpInfo.groupId;
      }else {
        return this.stpGroup;
      }
    },
  },
  methods: {
    handleClose(){
      if(this.closeNeedRefresh){
        this.$emit('refresh');
      }
      this.closeNeedRefresh = false;
      this.$emit('close');
    },
    close(){
      this.resetForm();
      this.$emit('close');
    },
    closeSetOriginal(){
      if(this.rowData.ibRank ==1){
        this.getSubLevelAndRule(0);
      }else {
        this.getSubLevelAndRule(this.rowData.belongId);
      }
      if(this.rowData.levelId){
        this.selfCustLvl = this.rowData.levelId
      }
      if(this.rowData){
        this.isAllowEditCommi = this.rowData.ibRank==2&&this.rowData.foreignConfig==1?false:true;
        this.isShowForeignSetup = this.rowData.ibRank==1 ||(this.rowData.ibRank==2&&this.rowData.foreignConfig==1)?true:false;
        this.foreignConfig = this.rowData.foreignConfig+'';
        this.foreignStatistics = this.rowData.foreignStatistics+'';
        // this.querySetting();
      }
    },
    unionSaveHandle(){
      let tips ='确定停用该代理子代理一致外佣配置？'
      if(this.foreignConfig=='1') {
        tips ='确定启用该代理子代理一致外佣配置？'
      }
      this.$confirm(tips,'子代理一致外佣配置确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let parmas = {
          id : this.merId,
          foreignConfig:this.foreignConfig
        }
        //启用接口
        updateCustInfoConfig(parmas).then(res => {
          this.closeNeedRefresh = true;
          this.$notify({
            title: '修改成功',
            type: 'success',
            duration: 1000
          });
        }).catch(err => {

        })
      }).catch(() => {
        //取消的提示
      });
    },
    computerSaveHandle(){
      let tips ='确定关闭该代理外佣统计功能？'
      if(this.foreignStatistics=='1') {
        tips ='确定打开该代理外佣统计功能？'
      }
      this.$confirm(tips,'外佣统计确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //打开接口
        let parmas = {
          id : this.merId,
          foreignStatistics:this.foreignStatistics
        }
        updateCustInfoConfig(parmas).then(res => {
          this.$notify({
            title: '修改成功',
            type: 'success',
            duration: 1000
          });
        }).catch(err => {

        })
      }).catch(() => {
        //取消的提示
      });
    },
    doSubmit(isLev) {
      this.loading = true
      let parmas = {
        merId : this.merId,
        outerType:this.outerType
      }
      if (isLev) {
        parmas['custLvl'] = this.selfCustLvl
      } else {
        if(this.outerComm == null || this.outerComm ==='') {
          this.$notify({
            title: '请输入加点数',
            type: 'error',
            duration: 2500
          })
          return
        }
        parmas['outerComm'] = this.outerComm
      }
      setCustLvlOuter(parmas).then(res => {
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.resetForm()
        this.loading = false;
        this.$emit('refresh');
        this.$emit('close');
      }).catch(err => {
        this.loading = false
      })
    },
    resetForm() {
      // this.form = {
      //   id: '',
      //   selfCustLvl: '',
      //   outerComm: 0
      // }
          this.stdGroup = '';
          this.proGroup = '';
          this.ecnGroup = '';
          this.centGroup = '';
          this.stpGroup = '';
          this.stdInfo = {
            groupId: 0,
            groupDetail:[],
          };
          this.proInfo = {
            groupId: 0,
            groupDetail:[],
          };
          this.ecnInfo = {
            groupId: 0,
            groupDetail:[],
          };
          this.centInfo = {
            groupId: 0,
            groupDetail:[],
          };
          this.stpInfo = {
            groupId: 0,
            groupDetail:[],
          };
         this.defaultGroup = '';
    },
    getSubLevelAndRule(custId){
      if( custId !==null ){
        querySubLevleAndRules({
          custId: custId
        }).then((res) => {
          this.ibLevelOptions = res;
        })
      }
    },
    commissionOuterSubmit(){
      // 全部分组和注册开户默认值保存.defaultGroup
      // 外佣设置非必选可清空 (但至少填一项) todo
      if(this.isMainAgent){
        if(!this.stdGroup && !this.proGroup && !this.ecnGroup && !this.centGroup && !this.stpGroup){
          this.$message({
            type: 'error',
            message: '外佣设置至少选择一项'
          })
          return;
        }
      }

      let params = {
        custId: this.rowData.id,
        defaultGroupId: this.defaultGroup,
      };
      let custGroups = [];
      if(this.stdInfo.groupId){
        custGroups.push({
          groupId: this.stdInfo.groupId,
          groupDetailId: this.stdGroup,
        })
      }
      if(this.proInfo.groupId){
        custGroups.push({
          groupId: this.proInfo.groupId,
          groupDetailId: this.proGroup,
        })
      }
      if(this.ecnInfo.groupId){
        custGroups.push({
          groupId: this.ecnInfo.groupId,
          groupDetailId: this.ecnGroup,
        })
      }
      // 加上美分组
      if(this.centInfo.groupId){
        custGroups.push({
          groupId: this.centInfo.groupId,
          groupDetailId: this.centGroup,
        })
      }
      if(this.stpInfo.groupId){
        custGroups.push({
          groupId: this.stpInfo.groupId,
          groupDetailId: this.stpGroup,
        })
      }
      params.custGroups = custGroups;
      saveGroupDetail(params).then((res) => {
        this.$notify({
          message: '修改成功',
          type: 'success'
        });

      })
    },
    getGroups(){
      queryGroupDetailAll().then((res) => {
        console.log(res);
        for (let i = 0; i <res.content.length ; i++) {
            if(res.content[i].groupName == 'STD'){
              this.stdInfo = res.content[i];
            }
            if(res.content[i].groupName == 'PRO'){
              this.proInfo = res.content[i];
            }
            if(res.content[i].groupName == 'ECN'){
              this.ecnInfo = res.content[i];
            }
            if(res.content[i].groupName == 'Cent'){
              this.centInfo = res.content[i];
            }
            if(res.content[i].groupName == 'STP'){
              this.stpInfo = res.content[i];
            }
        }
        this.querySetting();
      })
    },
    querySetting(){
      queryDefaultGroupDetail({
        custId: this.rowData.id,
      }).then((res) => {
        if(res.defaultGroupId){
          this.defaultGroup = res.defaultGroupId;
        }
        for (let i = 0; i < res.custGroups.length; i++) {
          if(res.custGroups[i].groupId == this.stdInfo.groupId){
            this.stdGroup = res.custGroups[i].groupDetailId + '';
          }
          if(res.custGroups[i].groupId == this.proInfo.groupId){
            this.proGroup = res.custGroups[i].groupDetailId + '';
          }
          if(res.custGroups[i].groupId == this.ecnInfo.groupId){
            this.ecnGroup = res.custGroups[i].groupDetailId + '';
          }
          if(res.custGroups[i].groupId == this.centInfo.groupId){
            this.centGroup = res.custGroups[i].groupDetailId + '';
          }
          if(res.custGroups[i].groupId == this.stpInfo.groupId){
            this.stpGroup = res.custGroups[i].groupDetailId + '';
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.child_row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0;
  }
.child_ol {
    border-radius: 4px;
  }
.label {
    font-size: 18px;
    padding: 10px 10px;
  }
  .outer-commission-set {
     display: flex;
     justify-content: flex-start;
     align-items: flex-start;
     margin: 10px;
     padding: 10px;
    .set-title {
      text-align: right;
      width:180px;
      margin-right: 20px;
      font-size: 20px;
      &::before {
        content: '*';
        color: #f56c6c;
        margin-right: 4px;
      }
      &.no-star::before {
        display: none;
      }
      &.is-sub-agent::before {
        display: inline;
      }
    }
    .group-line {
      margin-bottom: 10px;
    }
    .margin-right10 {
      margin-right: 10px;
    }
    .default-select-group {
      margin-right: 20px;
    }
  }
</style>
