<template>
  <div class="app-container">
    <eHeader
        :query="query"
        @clear="clear"
        @toQuery="toQuery"
        @custLvlClearHandle="custLvlClearHandle"
        ref="eheader"
    />
    <!--表格渲染-->
    <el-table
        v-loading="loading"
        :data="data"
        stripe
        size="small"
        style="width: 100%;"
    >
      <el-table-column prop="id" label="代理ID" width="80px"/>
      <el-table-column prop="custType" label="代理类别"  width="70">
        <template slot-scope="scope">
          <span>{{ parseType(scope.row.custType)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="levelName" label="代理级别"  width="70" />
      <el-table-column prop="ibRank" label="代理层级"  width="70">
        <template slot-scope="scope">
          <span>{{ getAgentLevel(scope.row.ibRank) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="代理名称" />
      <el-table-column label="上级信息" width="150">
         <template slot-scope="scope">
           <div v-if="scope.row.belongId!=null">{{scope.row.belongName}}</div>
            <div v-if="scope.row.belongId!=null">{{scope.row.belongId}}</div>
            <div v-if="scope.row.belongId==null">无</div>
        </template>
      </el-table-column>
      <el-table-column prop="manager" label="客户经理">
        <template slot-scope="scope">
            <span v-if="scope.row.managerName!=null">{{scope.row.managerName}}<br></span>
            <span v-if="scope.row.managerId!=null">{{scope.row.managerId}}</span>
            <span v-if="scope.row.managerName==null&&scope.row.managerId==null">无</span>
        </template>
      </el-table-column>
      <el-table-column  label="联系方式" width="150">
         <template slot-scope="scope">
            <span v-if="scope.row.phone!=null">{{scope.row.phone}}<br></span>
            <span v-if="scope.row.email!=null">{{scope.row.email}}</span>
            <span v-if="scope.row.phone==null&&scope.row.email==null">无</span>
        </template>
      </el-table-column>
      <el-table-column prop="sectorCode" label="业务范围">
         <template slot-scope="scope">
          <span>{{ parseBussSectors(scope.row.sectorCode) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mt4MainAcc" label="MT4主账号"/>
      <el-table-column prop="auditStatus" label="代理状态">
        <template slot-scope="scope">
          <span>{{ parseIbStatus(scope.row.auditStatus)}}</span>
          <span><br>{{ parseCusStatus(scope.row.custStatus)}}</span>
        </template>
      </el-table-column>
       <el-table-column prop="applyDate" label="申请时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.applyDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核通过时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.auditTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280px" align="center">
        <template slot-scope="scope">
          <el-button
                  v-if="!isCheck&&checkPermission(['ADMIN','CUSTMTACCOUNT_ALL','CUSTMTACCOUNT_SELECT'])"
                  size="mini"
                  type="success"
                  @click="to(1,scope.row)"
          >查看</el-button>
          <el-button
                  v-if="!isCheck&&checkPermission(['ADMIN','CUST_SERVER_ALL','CUST_SERVER_SELECT'])"
                  class="margin-right5"
                  size="mini"
                  type="success"
                  @click="severLookHandle(scope.row)"
          >客服查看</el-button>

          <el-dropdown
            size="mini"
            v-if="getMoreBtnShow(scope.row)"
          >
            <el-button size="mini" type="success">
              更多操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                class="dropdown-item"
                v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_EDIT'])"
              >
                <edit class="my-button"  :data="scope.row" :sup_this="sup_this"/>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="isCheck && checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_AUDIT','CUST_ALL','CUST_AUDIT'])"
              >
                <div @click="to(0)">审核</div>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_CHANGE'])"
              >
                <div
                  @click="transferHandle(scope.row)"
                >转移</div>
              </el-dropdown-item>
              <el-dropdown-item
                  class="dropdown-item"
                  v-if="getNotActive(scope.row) && checkPermission(['ADMIN','CUST_ALL','CUST_AUDIT'])"
              >
                <div
                    @click="activeHandle(scope.row)"
                >激活用户</div>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_CUST'])"
              >
                <span @click="clientAccountHandle(scope.row)">客户账户</span>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_COMM'])"
              >
                <div @click="clientBtnOrrebateBtnHandle(scope.row)">返佣</div>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_COMM_SET'])"
              >
                <span @click="comsteupHandle(scope.row)">返佣设置</span>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_CUST'])"
              >
                <div @click="clientLevelHandle(scope.row)">代理层级</div>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="getExperienceBtnShow(scope.row)"
              >
                <div @click="openExperienceHandle(scope.row)">体验账户</div>
              </el-dropdown-item>
              <el-dropdown-item
                  class="dropdown-item"
              >
                <div
                    v-if="getCan(scope.row) && checkPermission(['ADMIN','CUST_LINK_ALL','CUST_LINK_CREATE'])"
                    @click="addCustomerLinks(scope.row)"
                >添加直客链接</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <tranfer-client-type-dialog
      :dialog-show="clientLevelDialogShow"
      @close-dialog="clientLevelDialogShow = false"
      :client-info="currentClientInfo"
    ></tranfer-client-type-dialog>
    <agency-transferform
      ref="agencytransferform"
      :row-data="currentClientInfo"
    ></agency-transferform>

    <comsteupform
        :row-data="currentClientInfo"
        ref="comsteupform"
        :dialog-show="comsteupDialogShow"
        @refresh="refreshHandle"
        @close="comsteupDialogShow = false"
    ></comsteupform>
    <detail
        ref="detail"
        :sup_this="sup_this"
        :isCheck="isCheck"
        :data="viewData"
        :isShowSubLink="isShowSubLink"
    />
    <el-dialog title="权限验证" :visible.sync="dialogFormVisible" append-to-body width="30%">
      <div class="send-li">
        <li>
          <span class="code-font">手机验证码</span>
        </li>
        <li>
          <el-input placeholder="请输入手机验证码" v-model="smsCode"></el-input>
        </li>
        <li>
          <span v-show="show" @click="getCode" class="count">获取验证码</span>
          <span v-show="!show" class="count">{{count}} s</span>
        </li>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCode">确 定</el-button>
      </div>
    </el-dialog>
    <open-experience-dialog
        :dialog-show="dialogShow"
        :cust-id="currentCustId"
        @success-done="successDoneHandle"
        @close="closeHandle"
    ></open-experience-dialog>
    <add-links-dialog
      :dialog-show="addLinksDialogShow"
      @close="addLinksDialogShow = false"
      :cust-id="custId"
      @do-success="doSuccess"
    ></add-links-dialog>
  </div>
</template>

<script>
  import { activeUserLink } from '@/api/ib/ibMerchantInfo'
  import addLinksDialog from '@/components/ib/merchantinfo/addLinks';
  import { mapGetters } from 'vuex'
  import openExperienceDialog from '@/components/public/openExperienceDialog'
  // viewDetail的操作start
  import detail from "@/components/ib/merchantinfo/detail";
  import viewData from '@/mixins/viewData'
  // viewDetail的操作end
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import { parseBussSectors,parseType,parseIbStatus,parseCusStatus,parseIbRank } from '@/views/pt/ib/utils/index'
import eHeader from '@/components/ib/merchantinfo/header'
import edit from '@/components/ib/merchantinfo/edit'
import viewDetail from '@/components/ib/merchantinfo/view'
import addcus from '@/components/ib/merchantinfo/addcus'
import comsteupform from '@/components/ib/merchantinfo/comsteupform'

import agencyTransferform from '@/components/ib/merchantinfo/agencyTransferform'
import tranferClientTypeDialog from '@/components/ib/merchantinfo/tranferClientTypeDialog'
  import { updateGiveGoldStatus } from '@/api/bussetup/withGold'
export default {
  name:'merchantinfo',
  components: {
    eHeader,
    edit,
    viewDetail,
    addcus,
    comsteupform,
    agencyTransferform,
    tranferClientTypeDialog,
    detail,
    openExperienceDialog,
    addLinksDialog,
  },
  mixins: [initData,viewData],
  data() {
    let _this = this;
    return {
      delLoading: false,
      sup_this: _this,
      clientLevelDialogShow: false,
      currentClientInfo: {
        levelId: '',
        ibRank: '',
        foreignConfig: '',
        foreignStatistics: '',
      },
      currentCustId: 0,
      dialogShow: false,
      addLinksDialogShow: false,
      custId: 0,

      comsteupDialogShow: false,
    }
  },
  created() {
    //代理级别点击代理数量跳转过来
    if(this.merchantCustLvl){
      this.query.custLvl = this.merchantCustLvl;
    }
    this.$nextTick(() => {
      this.init()
    })
    if(this.$store.getters.bussSectors==null){
       this.$store.dispatch('GetBussSectors')
    }
  },
  beforeDestroy(){
    this.$store.commit('setMerchantCustLvl','');
  },
  computed: {
    ...mapGetters(['merchantCustLvl']),
  },
  watch:{
    merchantCustLvl(newVal){
      this.query.custLvl = newVal;
      this.$refs.eheader.query.custLvl = newVal;
      this.$refs.eheader.$forceUpdate();
      this.init();
    }
  },
  methods: {
    parseTime,
    checkPermission,
    parseType,
    parseIbStatus,
    parseCusStatus,
    parseIbRank,
    parseBussSectors,

    getCan(row){
      return !row.foreignConfig;
    },
    getAgentLevel(ibRank){
      return ibRank == 1 ? '主代理': '子代理';
    },
    getNotActive(row){
      return row.custStatus == 10;
    },
    activeHandle(row){
      this.$confirm(`确认激活该用户`,'提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        //点击确定的操作(调用接口)
        activeUserLink(row.id).then((res) => {
          this.$message({
            type: 'success',
            message: '激活成功',
          })
          this.init();
        })
      }).catch(() => {
        //几点取消的提示
      });
    },
    doSuccess(){
      this.addLinksDialogShow = false;
      this.init();
    },
    addCustomerLinks(row){
      this.custId = row.id;
      this.addLinksDialogShow = true;
    },
    custLvlClearHandle(){
      delete this.query.custLvl;
      delete this.$refs.eheader.query.custLvl;
      this.$refs.eheader.$forceUpdate();
      this.toQuery();
    },
    refreshHandle(){
      this.init();
    },
    beforeInit() {
      this.url = 'crm/tCustInfo';
      const sort = 'applyDate,desc';
      this.params = {
        custTypeStr:'1,2',
        page: this.page,
        size: this.size,
        sort: sort,
      };
      const query = this.query;
      const basicInfo = query.basicInfo;
      const custType = query.custType;
      const auditStatus = query.auditStatus;
      const custStatus = query.custStatus
      let id = query.id;

      const managerInfo = query.managerInfo
      const belongInfo = query.belongInfo
      const ibRank = query.ibRank
      if (basicInfo!=null && basicInfo!='') { this.params['basicInfo'] = basicInfo }
      if (custType!='') { this.params['custType'] = custType }
      if (managerInfo!='') { this.params['managerInfo'] = managerInfo }
      if (this.query.custLvl) { this.params['levelId'] = this.query.custLvl }
      if (auditStatus!='') { this.params['auditStatus'] = auditStatus }
      if (custStatus!=null && custStatus!='') { this.params['custStatus'] = custStatus }
      if (id!=null && id!='') { this.params['id'] = id }
      if (belongInfo!=null && belongInfo!='') { this.params['belongInfo'] = belongInfo }
      if (ibRank!=null && ibRank!='') { this.params['ibRank'] = ibRank }
      if(this.query.startAuditTime){
        this.params.startAuditTime = this.parseTime(this.query.startAuditTime);
      }
      if(this.query.endAuditTime){
        this.params.endAuditTime = this.parseTime(this.query.endAuditTime);
      }
      return true
    },
    // 跳去 账户管理页面,帅选出代理归属为所点击代理的所有账号,
    clientAccountHandle(row){
      this.$router.push({
        path: '/pt/customer/accountmgr',
        query: { agencyId:  row.id }
      });
    },
    clientLevelHandle(row){
      this.clientLevelDialogShow = true;
      this.currentClientInfo = row;
    },
    transferHandle(row){
      this.currentClientInfo = row;
      const _this = this.$refs.agencytransferform
      _this.userId = row.id,
        _this.belongId = row.belongId,
        // _this.managerId = this.data.managerId,
        _this.initGetManagerlist(row.managerId);
      _this.dialog = true;
    },
    clientBtnOrrebateBtnHandle(row){
      this.$router.push({
        path:'/pt/fin/commidetail',
        query:{
          type:2,
          queryCon:row.id.toString()
        }
      })
    },
    getExperienceBtnShow(row){
      return row.auditStatus == 4 && !row.hasTrial &&  this.checkPermission(['ADMIN','OPEN_TRIAL_ACCOUNT'])
    },
    openExperienceHandle(row){
      this.dialogShow = true;
      this.currentCustId = row.id;
    },

    comsteupHandle(row){
      this.currentClientInfo = row;
      const _this = this.$refs.comsteupform;
      _this.merId = row.id;
      _this.custLvl = row.custLvl;
      _this.outerComm = row.outerComm;
      _this.outerType = row.outerType;

      this.comsteupDialogShow = true;
      _this.resetForm();
    },
    successDoneHandle(){
      this.init();
    },
    closeHandle(){
      this.dialogShow = false;
    },
    clear(){
      this.query = { };
      this.toQuery();
    },
    getMoreBtnShow(row){
      return checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_EDIT']) || (this.isCheck && checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_AUDIT','CUST_ALL','CUST_AUDIT'])) || checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_CHANGE']) || checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_CUST']) || checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_COMM']) || checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_COMM']) || checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_CUST']) || this.getExperienceBtnShow(row);
    },
  }
}
</script>

<style lang="less" scoped>

</style>
